import toast from "react-hot-toast"

export const baseURL = "https://easycrypto.space/api/api.php"

const appAPI = {
    async getUser(userId) {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    action: "userinfo",
                    pid: userId
                })
            }
            const res = await fetch(baseURL, options).then((res) => res.json())
            if (res.error) return toast.error(res.error)
            return res
        } catch (e) {
            console.log(e)
        }

    },
    async getPayments(country) {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "action": "scancurrencylist",
                    "country": country
                })
            }
            const res = await fetch(baseURL, options).then((res) => res.json())
            if (res.error) return toast.error(res.error)
            return res
        } catch (e) {
            console.log(e)
        }

    },
    async getCountries() {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "action": "scancountrylist"
                })
            }
            const res = await fetch(baseURL, options).then((res) => res.json())
            if (res.error) return toast.error(res.error)
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async getCourse(first, second) {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "action": "course",
                    in: first,
                    out: second
                })
            }
            const res = await fetch(baseURL, options).then((res) => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async getRequisites(country, valute) {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "action": "requisites",
                    country: country,
                    valute: valute,
                    chat_id: window.Telegram.WebApp.initDataUnsafe.user.id
                })
            }
            const res = await fetch(baseURL, options).then((res) => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async postOrder(data) {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "action": "createpayment",
                    ...data
                })
            }
            const res = await fetch(baseURL, options).then((res) => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
}


export default appAPI