

const Loader = () => {
    return (
        <div className="loader">
            <img src="/loading.png" className="loading_png"/>
            <img src="/loader1.svg" className="loading_svg"/>
        </div>
    )
}

export default Loader