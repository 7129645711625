import { useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Loader from './assets/loader/loader'
import words from './assets/lang/lang.json';
import appAPI from "./api/service";
import * as uuid from "uuid"
import Done from "./pages/done";

function App() {

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const [userInfo, setUserInfo] = useState(null)
  const [payments, setPayments] = useState(null)
  const [countries, setCountries] = useState(null)
  const [limits, setLimits] = useState(null)

  const [orderId, setOrderId] = useState(null)

  const [course, setCourse] = useState(null)
  const [requisites, setRequizits] = useState(null)
  const [userAmount, setUserAmount] = useState(null)
  const [comment, setCommnet] = useState(null)
  const [requizitsInfo, setRequizitsInfo] = useState(null)
  const [ready, setReady] = useState(false)


  const [country, setCountry] = useState("")
  const [payment, setPayment] = useState("")
  const [amount, setAmount] = useState("")

  const [check, setCheck] = useState(null)
  const [qr, setQr] = useState(null)
  const [reqizitesText, setRequizitsText] = useState("")

  const [accept1, setAccept1] = useState(false)
  const [accept2, setAccept2] = useState(false)
  const [accept3, setAccept3] = useState(false)

  const [isLangSelected, setIsLangSeceted] = useState(false);


  const [progressBarQr, setProggressBarQr] = useState(0)
  const [progressBarCheck, setProggressBarCheck] = useState(0)


  const [lang, setLang] = useState("ru")

  useEffect(() => {
    if (localStorage.getItem("MIXPAYLANG")) {
      setLang(localStorage.getItem("MIXPAYLANG"))
    } else {
      localStorage.setItem("MIXPAYLANG", "ru")
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("MIXPAYLANG", lang)
  }, [lang])



  const declineAll = () => {
    setTimeout(() => {
      setAccept1(false)
      setAccept2(false)
      setAccept3(false)
      setCommnet(null)
      setCourse(null)
      setUserAmount(null)
      setRequizitsInfo(null)
      setRequizits(null)
      setCommnet(null)
      setAmount("")
      setCheck(null)
      setQr(null)
      setCountry("")
      setPayment("")
    }, 400)
  }


  const handleLanguageToggle = useCallback(() => {
    setIsLangSeceted((prevState) => !prevState);
  }, []);

  const handleCheckFileSelect = (event) => {
    const file = event.target.files[0];
    convertFileToBase64(file, setCheck, setProggressBarCheck);
  };

  const handleQrFileSelect = (event) => {
    const file = event.target.files[0];
    convertFileToBase64(file, setQr, setProggressBarQr);
  };

  const convertFileToBase64 = (file, setState, setProggress) => {
    const reader = new FileReader();
    reader.onloadstart = () => console.log("Starting to read the file...");

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentLoaded = Math.round((event.loaded / event.total) * 100);
        setProggress(percentLoaded)
        console.log(`Loading: ${percentLoaded}% complete`);
      }
    };

    reader.onloadend = () => {
      if (reader.error) {
        console.log("Error reading file:", reader.error);
      } else {
        setState(reader.result);
      }
    };

    // Чтение файла как Data URL
    reader.readAsDataURL(file);
  };

  const getPayments = async () => {
    const payments = await appAPI.getPayments(country)
    if (payments.error) return toast.error(payments.error)
    if (!payments) return setError("error")
    const paymentsArray = Object.values(payments);
    setPayments(paymentsArray)
  }



  useEffect(() => {
    getPayments()
  }, [country])

  useEffect(() => {
    if (!window.Telegram.WebApp.initDataUnsafe.user) return setError("error")
    window.Telegram.WebApp.expand()
    async function fetch() {
      const user = await appAPI.getUser(window.Telegram.WebApp.initDataUnsafe.user.id)
      if (user.error) return toast.error(user.error)
      if (!user) return setError("error")
      setUserInfo(user)
      const countries = await appAPI.getCountries()
      if (countries.error) return toast.error(countries.error)
      if (!countries) return setError("error")
      const countriesArray = Object.values(countries);
      setCountries(countriesArray)
    }
    fetch()
  }, [])


  const handleFocus = () => {
    document.body.classList.add('with-keyboard');
  };

  const handleBlur = () => {
    document.body.classList.remove('with-keyboard');
  };

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const data = {
        userId: userInfo.chat_id,
        country: country,
        paymentType: (payment.split(" ")[0]),
        exchangeRate: course,
        exchangeAmount: userAmount,
        exchangeCurrency: (payment.split(" ")[0]),
        receiveAmount: parseInt(amount),
        receiveCurrency: country,
        qrCode: qr,
        check: check,
        comment: (payment.split(" ")[0] !== "USDT" ? comment : ""),
        user_requisites: reqizitesText,
        requisites: requizitsInfo,
        ...limits
      }
      const res = await appAPI.postOrder(data)
      if (res.error) return toast.error(res.error)
      if (res.status === "success") {
        setReady(true)
        setLoading(false)
        declineAll()
        setOrderId(res)
      } else {
        return toast.error("Ошибка при отправке данных")
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  function validateNameFormat(name) {
    const regex = /^[А-Яа-я]+ [А-Я]\.$/;
    return regex.test(name);
  }

  const classifyRequisites = (requisites) => {
    return requisites.map((req) => {
      if (req.startsWith('+')) {
        return { type: 'phone', value: req };
      }
      else if (req.replace(/\s/g, "").length === 16 && !isNaN(parseInt(req, 10))) {
        return { type: 'card', value: req };
      }
      else if (validateNameFormat(req.trim())) {
        return { type: 'name', value: req };
      }
      else if (req.length > 30 && isNaN(parseInt(req, 10))) {
        return { type: 'address', value: req };
      }
      else {
        return { type: 'unknown', value: req };
      }
    });
  };

  const handlerAccept1 = async () => {
    setLoading(true)
    try {
      const valute = (payment.split(" ")[0])
      const courseI = await appAPI.getCourse(valute, country)
      if (courseI.error) return toast.error(courseI.error)
      if (parseInt(amount) > parseInt(courseI.maxLimit) || parseInt(amount) < parseInt(courseI.minLimit)) return toast.error(`${words[lang].alert_3_1} ${courseI.minLimit} ${words[lang].alert_3_2} ${courseI.maxLimit}`)
      setLimits({ minLimit: courseI.minLimit, maxLimit: courseI.maxLimit })
      setUserAmount((payment.split(" ")[0]) === "USDT" ? (Number(amount) / Number(courseI[country])).toFixed(2) : Math.ceil(Number(amount) / Number(courseI[country])))
      setCommnet(Math.floor(1000000 + Math.random() * 9000000))
      setCourse(courseI[country])
      setAccept1(true)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handlerAccept2 = async () => {
    try {
      const valute = (payment.split(" ")[0])
      const requizit = await appAPI.getRequisites(valute, country)
      if (requizit.error) return toast.error(requizit.error)
      const classifiedRequisites = classifyRequisites(requizit.Requisites.split("\n"));
      setRequizits(classifiedRequisites);
      setRequizitsInfo(requizit.Requisites)
      setAccept2(true);
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied!");
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };


  if (!lang) return <Loader />
  if (!userInfo && !payments && !countries && !error) return <Loader />
  if (!words[lang]) return localStorage.setItem("MIXPAYLANG", "ru")

  return (
    <div className="app">
      {!ready && !orderId && !error ? <>
        <Toaster />
        <div className="header">
          <img src="/logo.svg" className="logo" />
          <div className="select_header">
            {!isLangSelected ? <div onClick={handleLanguageToggle}>{lang === "ru" ? "RU" : "EN"} <img src="/arrow.svg" /></div>
              :
              <>
                <div onClick={handleLanguageToggle}>{lang === "ru" ? "RU" : "EN"} <img src="/arrow.svg" /></div>
                <div onClick={() => setLang((lang === "ru" ? "en" : "ru"))}>{lang !== "ru" ? "RU" : "EN"}</div>
              </>
            }

          </div>
        </div>

        <div className="form">
          <div className="input_block">
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}><div className="input_title">{words[lang].t_1} <span style={{ color: "#D98C4C" }}>({words[lang].receive})</span></div></div>
            <select className={`input custom-select ${country !== "" && "selected"}`} disabled={accept1} value={country} onChange={(e) => setCountry(e.target.value)}>
              <option value="">{words[lang].t_1}</option>
              {countries && countries.map(country => {
                return (
                  <option value={country.currency} key={country.currency}>{country[lang]} ({country.currency})</option>
                )
              })}
            </select>
          </div>
          <div className="input_block">
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}><div className="input_title">{words[lang].t_2}</div><span style={{ color: "#D98C4C" }}>({words[lang].give})</span></div>
            <select className={`input custom-select ${payment !== "" && "selected"}`} disabled={accept1} value={payment} onChange={(e) => setPayment(e.target.value)}>
              <option value="">{words[lang].t_2}</option>
              {payments && payments.map(payment => {
                return (
                  <option value={payment} key={payment}>{payment}</option>
                )
              })}
            </select>
          </div>
          <div className="input_block">
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}><div className="input_title">{words[lang].t_3} <span style={{ color: "#D98C4C" }}>{country}</span></div></div>
            <input
              type="text"
              disabled={accept1}
              className="input center_text"
              style={{ color: "black" }}
              placeholder="10000"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value)
              }}
            />
          </div>
          {!accept1 && payment && amount && country &&
            <div className="buttons">

              <div className="button_object" onClick={() => !loading ? declineAll() : ""}>{words[lang].object}</div>
              <div className="button_accept" onClick={() => {
                if (country === "") return toast.error(words[lang].t_1)
                if (payment === "") return toast.error(words[lang].t_2)
                if (amount <= 0 || isNaN(amount)) return toast.error(words[lang].alert_2);
                handlerAccept1()
              }}>{words[lang].accept}</div>
            </div>}
          {(accept1 && course && userAmount !== null && comment) ? <><div className="input_block">
            <div className="input_title">{words[lang].t_4}</div>
            <div className="block_check">
              <div>
                <div><div className="no_wrap">{words[lang].t_4_1} <span>{country}</span></div><div className="line_order"></div><div className="no_wrap">{amount} <span>{country}</span> {words[lang].t_4_2} <span>{(payment.split(" ")[0])}</span></div></div>
                <div>{words[lang].rate}: <span>{course}</span></div>
                <div className="amount">{words[lang].t_4_3}: <b>{userAmount}{(payment.split(" ")[0])}</b></div>
              </div>
              <div className="country_block">
                <div className="country">[{countries.find(coun => coun.currency === country)[lang]}]</div>
                <div className="important">{words[lang].t_4_4_1} {words[lang].t_4_4_2}</div>
              </div>
            </div>
          </div>
            {!accept2 && <div className="buttons">
              <div className="button_object" onClick={() => !loading ? declineAll() : ""}>{words[lang].object}</div>
              <div className="button_accept" onClick={() => handlerAccept2()}>{words[lang].accept}</div>

            </div>}</> : ""}


          {accept2 && requisites && <><div className="input_block">
            <div className="input_title">{words[lang].t_5}</div>
            <div className="block_check">
              <div className="info_box">
                {requisites.map(req => {
                  switch (req.type) {
                    case "card": {
                      return <div className="info " key={req.type}>
                        <div className="key">{words[lang].t_5_1}: </div>
                        <div className="value black_text">{req.value}</div>
                        <img src="/copy.svg" style={{ width: "16px", height: "16px" }} onClick={() => copyToClipboard(req.value)} />
                      </div>
                    }
                    case "phone": {
                      return <div className="info " key={req.type}>
                        <div className="key">{words[lang].t_5_2}: </div>
                        <div className="value black_text">{req.value}</div>

                      </div>
                    }
                    case "name": {
                      return <div className="info " key={req.type}>
                        <div className="key">{words[lang].t_5_3}: </div>
                        <div className="value black_text">{req.value}</div>

                      </div>
                    }
                    case "address": {
                      return <div className="info address " key={req.type}>
                        <div className="key">Address: </div>
                        <div className="value black_text">{req.value}</div>
                        <img src="/copy.svg" style={{ width: "16px", height: "16px" }} onClick={() => copyToClipboard(req.value)} />
                      </div>
                    }
                    default: {
                      return
                    }
                  }
                })}
                {comment && payment.split(" ")[0] !== "USDT" && <div className="info"><div className="key">{words[lang].t_5_4}: </div><span className="value">{comment}</span><img src="/copy.svg" style={{ width: "16px", height: "16px" }} onClick={() => copyToClipboard(comment)} /></div>}
                {payment.split(" ")[0] !== "USDT" && <div className="important req_important">{words[lang].t_5_5_1} <br /> {words[lang].t_5_5_2}</div>}
              </div>
            </div>
          </div>
            {!accept3 && <div className="buttons">
              <div className="button_object" onClick={() => !loading ? declineAll() : ""}>{words[lang].object}</div>
              <div className="button_accept" onClick={() => setAccept3(true)}>{words[lang].payed}</div>
            </div>}</>}

          {accept3 && <><div className="images">
            {!check ? <label className="button_check">
              <div>{words[lang].check_p}<img src="/doc.svg" /></div>
              <input type="file" accept="image/*" style={{ display: "none" }} onChange={handleCheckFileSelect} />
              <div className="proggress_bar">
                <div style={{ width: `${progressBarCheck}%`, display: "block" }}></div>
              </div>
            </label>
              :
              <label className="button_check accept">
                {words[lang].check}
                <img src="/good.svg" />
                <svg onClick={() => {
                  setCheck(null)
                  setProggressBarCheck(0)
                }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="rgba(255, 109, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="rgba(255, 109, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17 3L19 5M21 7L19 5M19 5L21 3M19 5L17 7" stroke="rgba(255, 109, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              </label>
            }


            <div className="req_block">
              {!qr ? <>
                <label className="button_qr">
                  <div>{words[lang].qr_p}<img src="/qr.svg" /></div>
                  <input type="file" accept="image/*" style={{ display: "none" }} onChange={handleQrFileSelect} />
                  <div className="proggress_bar">
                    <div style={{ width: `${progressBarQr}%` }}></div>
                  </div>
                </label>
              </>
                :
                <label className="button_qr accept">
                  {words[lang].qr}
                  <img src="/good.svg" />
                  <svg onClick={() => {
                    setQr(null)
                    setProggressBarQr(0)
                  }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="rgba(255, 109, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="rgba(255, 109, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17 3L19 5M21 7L19 5M19 5L21 3M19 5L17 7" stroke="rgba(255, 109, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </label>
              }
              <div style={{ color: "#ABB0BC" }}>или</div>
              <input onChange={(e) => setRequizitsText(e.target.value)} onFocus={handleFocus}
                onBlur={handleBlur} value={reqizitesText} className="reqizites" type="text" placeholder="Введите реквизиты" />
            </div>
          </div>

            <div className="buttons">
              <div className="button_object" onClick={() => !loading ? declineAll() : ""}>{words[lang].object}</div>
              {!loading ?
                accept3 && check && (qr || reqizitesText) && (qr ? Number(progressBarQr) === 100 : true) && Number(progressBarCheck) === 100 ?
                  <div className="button_accept " onClick={() => handleSubmit()}>{words[lang].ready} </div>
                  :
                  <div className="button_accept active_false">{words[lang].ready}</div>
                :
                <div className="button_accept">{words[lang].load}</div>
              }
            </div>

            {/*<div className="button_img" onClick={() => !loading ? declineAll() : ""}>{words[lang].object}</div>*/}

          </>
          }

          {/*accept3 && check && (qr || reqizitesText) && (!loading ? <div className="button finally_button" onClick={() => handleSubmit()}>
            {words[lang].ready}
          </div> : <div className="button finally_button">
            {words[lang].load}
        </div>)*/}

        </div></>



        : !error ?
          <Done lang={lang} orderInfo={orderId} setReady={setReady} setOrderId={setOrderId} /> : <div className="ready_page">
            Сервер не доступен. Попробуйте позже.
          </div>
      }
    </div>
  );
}

export default App;
