import { useState } from 'react';
import words from '../assets/lang/lang.json';
import { format } from 'date-fns';

const Done = ({setReady,setOrderId, orderInfo, lang}) => {
    const [data,setData] = useState(Date.now())
    return (
        <div className="ready_page">
          <img src="/donelogo.svg" className='donelogo'/>
          <div className='donetime'>{format(data,"dd.LL.yyyy")} {format(data,"H:mm:ss")}</div>
          <div className='doneline'>
            <div className='done_sum'>Итого</div>
            <div className='done_prcie'>{orderInfo.paymentDetails.receiveAmount} {orderInfo.paymentDetails.receiveCurrency}</div>
          </div>
          <div className='done_divider'></div>
          <div className='done_orderinfo'>
            <div className='doneline'>
              <div className='done_title'>Тип обмена:</div>
              <div className='done_value'>{orderInfo.paymentDetails.qrCode ? "ScanPay QR" : "Перевод по реквизитам"}</div>
            </div>
            <div className='doneline'>
              <div className='done_title'>Страна:</div>
              <div className='done_value'>{orderInfo.paymentDetails.countryName}</div>
            </div>
            <div className='doneline'>
              <div className='done_title'>Статус:</div>
              <div className='done_value'>Принят в обработку</div>
            </div>
            <div className='doneline'>
              <div className='done_title'>Сумма:</div>
              <div className='done_value'>{orderInfo.paymentDetails.exchangeAmount} {orderInfo.paymentDetails.exchangeCurrency}</div>
            </div>
            {window.Telegram.WebApp.initDataUnsafe.user.username ? <div className='doneline'>
              <div className='done_title'>Пользователь:</div>
              <div className='done_value'>@{window.Telegram.WebApp.initDataUnsafe.user.username}</div>
            </div> : <div className='doneline'>
              <div className='done_title'>Пользователь:</div>
              <div className='done_value'>@{orderInfo.paymentDetails.userId}</div>
            </div>}
            <div className='doneline'>
              <div className='done_title'>Идентификатор:</div>
              <div className='done_value'>{orderInfo.pid}</div>
            </div>
            {orderInfo.paymentDetails.comment && <div className='doneline'>
              <div className='done_title'>Комментарий:</div>
              <div className='done_value'>{orderInfo.paymentDetails.comment}</div>
            </div>}
          </div>

          <img src="/print.svg" className='done_print' />

          <div className='done_divider'></div>

          <div className='done_textblock'>
            <div className='done_textblock_first'>เลขที่ใบอนุญาต MC125660017</div>
            <div className='done_textblock_second'>พระราชบัญญัติควบคุมการแลกเปลี่ยนเงิน พุทธศักราช 2485</div>
            <div className='done_textblock_ru'>При возникновении затруднений обратитесь в службу поддержки <span onClick={() => window.open("https://t.me/mixpay_support")}>@mixpay_support</span></div>
          </div>


{/*}
            {words[lang].alert_1} : <br />{orderInfo.pid}
            <div className="button_ready" onClick={() => {
              setReady(false)
              setOrderId(null)
            }}>{words[lang].ready_s}</div>

*/}
          </div>
    )
}

export default Done